import { Dayjs } from 'dayjs';
import { Key, ReactNode } from 'react';

export type IndInvitation = {
  id: string;
  orgId: string;
  orgName: string;
  email: string;
};

export type IndInvitationResult = {
  id: string;
  orgId: string;
  orgName: string;
  email: string;
  result?: 'success' | 'error' | 'already-invited';
};

export enum OrganizationRole {
  Admin = 'org_admin',
  Member = 'org_member',
  Viewer = 'org_viewer',
}

// This is distinct from IndUser (although at some point we'll likely harmonize these types).
export type OrganizationMember = {
  uuid: string;
  email: string;
  role: DefaultOrgRoles;
  firstName: string;
  lastName: string;
  friendlyName: string;
  status: 'pending' | 'accepted' | 'invalid' | 'expired';
  assignedInds: number;
  isActive: boolean;
  invitationId: string;
};

export type Organization = {
  id: string;
  slug: string;
  name: string;
  type: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  members: Array<OrganizationMember>;
};

export type OrganizationStats = {
  name: string;
  totalInds: number;
  totalUsers: number;
};

export type OrgRoleId = {
  org_role_id: string;
};

export type OrgRoleData = {
  name: string;
  description?: string;
};

export type DefaultOrgRoles = 'org_admin' | 'org_member' | 'org_viewer';

export type CreatorUser = {
  email: string;
  first_name: string;
  invitation_id: string;
  invitation_status: 'pending' | 'accepted' | 'invalid';
  is_active: boolean;
  last_name: string;
  role: DefaultOrgRoles;
  uuid: string;
};

export type OrganizationRoles = {
  creator_user: CreatorUser | null;
  description: string | null;
  id: string;
  name: string;
  user_count: number;
  role_type: DefaultOrgRoles;
  key: string;
};

export type OrganizationRoleDetail = {
  blueprint_access_data: DossierTree;
  creator_user: CreatorUser | null;
  description: string | null;
  id: string;
  key: string;
  name: string;
  role_type: DefaultOrgRoles;
  user_count: number;
};

export type Permissions = {
  can_create: boolean;
  can_delete: boolean;
  can_read: boolean;
  can_update: boolean;
};

export type UserPermissions = {
  permissions: Permissions;
  secured_entity: string;
};

export type UserRoleData = {
  userId: string;
  roleIds: string[];
};

export type OrgTabAction = {
  icon: ReactNode;
  label: string;
  onClick: () => void;
};

export type DossierItems = {
  key: Key;
  name: string;
  selected: boolean;
  children: DossierItems[];
  is_document: boolean;
};

export type DossierTree = {
  items: DossierItems[];
};
