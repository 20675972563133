import cloneDeep from 'clone-deep';
import { useContext, useEffect, useState } from 'react';
import { useAsync, useDeepCompareEffect, useLatest } from 'react-use';

import UserContext from '~/core/contexts/UserContext';
import { useIsFeatureEnabled } from '~/core/hooks/core/useIsFeatureEnabled';
import { useToast } from '~/core/hooks/core/useToast';
import logger from '~/core/providers/logger';
import { isProcessing } from '~/core/services/EntityService';
import * as FileService from '~/core/services/FileService';
import {
  useGlobalStore,
  useGlobalStoreActions,
} from '~/core/store/global-store';
import { useReferencesStore } from '~/core/store/references-store';
import { useSidebarPanelsStore } from '~/core/store/sidebar-panels-store';
import { SECONDS } from '~/core/utils/const';
import { TemplateTableBlock } from '~/features/auto-tables/domain/types';
import * as TemplateService from '~/features/auto-tables/services/TemplateService';
import * as DocumentEditorService from '~/features/document-editor/services/DocumentEditorService';
import * as TableService from '~/features/document-editor/services/TableService';
import { VersionControlRecord } from '~/features/version-control/types';
import { useUserPermissionsMap } from '~/global-hooks/useUserPermissionsMap';

import { filterForBlockType } from '../components/organisms/FrontMatter/logic';
import { DocumentSection, TopLevelDocumentBlock } from '../domain/types';
import { DocumentReferenceFileTableSummary } from '../services/TableService/types';
import { frontMatterArray, FrontMatterSection } from './constants';

const DEFAULT_POLLING_INTERVAL = 5 * SECONDS;

export type DocumentLens =
  | 'edit'
  | 'preview'
  | 'refinement'
  | 'review'
  | 'references'
  | 'auto-update'
  | 'version'
  | 'version-preview';

export type LensCondition = {
  condition: boolean;
  lens: DocumentLens;
};

export type InsertTemplatedTableRequest = {
  templateId: string;
  sectionNumber: string;
  blockPositionToInsert: number;
  fileIds: Array<string>;
  initializeSection?: boolean;
};

export const useDocument = ({
  indId,
  initialDocumentNumber,
  intervalToCheckForGeneratingSections = DEFAULT_POLLING_INTERVAL,
  fileServiceImpl,
}: {
  indId: string;
  initialDocumentNumber: string;
  intervalToCheckForGeneratingSections?: number;
  fileServiceImpl?: any;
}) => {
  // @j-weave: This actually isn't necessary if the document side bar is receiving its state
  // from the useDocument hook (which it should be in the future).
  // However, I've placed this in here for now b/c the original refinement
  // relies on these being set whenever the blocks are focused.
  const { setSelectedBlocks } = useGlobalStoreActions();
  const { setIsPendingReferencesRefreshAfterEdit } = useReferencesStore();
  const { hasPermission } = useUserPermissionsMap();

  const fileService = fileServiceImpl || FileService;
  const toast = useToast();

  const [documentSections, setDocumentSections] = useState<
    Array<DocumentSection>
  >([]);

  const [frontMatterSections, setFrontMatterSections] = useState<
    Array<FrontMatterSection>
  >([]);

  const mostRecentDocumentSections = useLatest(documentSections);

  const [indFiles, setIndFiles] = useState<Array<any>>([]);

  const [documentNumber, setDocumentNumber] = useState<string>(
    initialDocumentNumber,
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLockedToUser, setIsLockedToUser] = useState(false);
  const [lockHolder, setLockHolder] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [tableSummariesLoaded, setTableSummariesLoaded] = useState(false);
  const [documentStatus, setDocumentStatus] =
    useState<Maybe<DocumentSectionStatus>>();
  const [documentId, setDocumentId] = useState<string | null>(null);

  const showTemplatedTableInstructions = useIsFeatureEnabled(
    'fe-show-templated-table-instructions',
  );

  const canEditDocument = hasPermission({
    securedEntity: 'ind.Document',
    documentId: documentId,
    indId,
  })?.can_update;

  const [currentFocusedElement, setCurrentFocusedElement] = useState<{
    sectionNumber: string;
    blockId: string;
    blockIndex: number;
  }>({
    sectionNumber: '',
    blockId: '',
    blockIndex: -1000,
  });

  const { userProfile: currentUserProfile } = useContext(UserContext);

  useEffect(() => {
    asyncGetDocumentLock();
  }, [currentUserProfile.email]);

  const [hasPreviewVersionRecord, setHasPreviewVersionRecord] = useState(false);

  const {
    isReferencesPanelOpen,
    isRefinementPanelOpen,
    isReviewPanelOpen,
    isAutoUpdatePanelOpen,
    isVersionHistoryPanelOpen,
    setIsReferencesPanelOpen,
    setIsRefinementPanelOpen,
    setIsReviewPanelOpen,
    setIsVersionHistoryPanelOpen,
    setIsAutoUpdatePanelOpen,
  } = useSidebarPanelsStore();

  const [currentLens, setCurrentLens] = useState<DocumentLens>('edit');

  // @jeni -> @j-weave: This is a state for loading state on version restore
  const [isReloadingDocumentContent, setIsReloadingDocumentContent] =
    useState<boolean>(false);
  const [versionDocumentSectionList, setVersionDocumentSectionList] = useState<
    DocumentSection[] | null
  >([]);
  const { setPreviewVersionRecord } = useGlobalStore();
  // For Version History
  const versionDocumentTables: TopLevelDocumentBlock[] = filterForBlockType(
    versionDocumentSectionList || [],
    ['table', 'grid_table'],
  );

  const versionDocumentFigures: TopLevelDocumentBlock[] = filterForBlockType(
    versionDocumentSectionList || [],
    ['image'],
  );

  const resetPreviewVersionRecord = async () => {
    setPreviewVersionRecord(null);
    setVersionDocumentSectionList(null);
    await loadPreviewVersion(null);
  };

  useEffect(() => {
    if (hasPreviewVersionRecord) {
      setCurrentLens('preview');
    } else if (isVersionHistoryPanelOpen) {
      setCurrentLens('version');
    } else if (isReferencesPanelOpen) {
      setCurrentLens('references');
    } else if (isRefinementPanelOpen) {
      setCurrentLens('refinement');
    } else if (isReviewPanelOpen) {
      setCurrentLens('review');
    } else if (isAutoUpdatePanelOpen) {
      setCurrentLens('auto-update');
    } else {
      setCurrentLens('edit');
    }
  }, [
    hasPreviewVersionRecord,
    isReferencesPanelOpen,
    isRefinementPanelOpen,
    isReviewPanelOpen,
    isAutoUpdatePanelOpen,
    isVersionHistoryPanelOpen,
  ]);

  useEffect(() => {
    if (documentSections.length > 0) {
      setFrontMatterSections(frontMatterArray);
      setIsLoading(false);
    }
  }, [documentSections]);

  const shiftLens = (lens: DocumentLens) => {
    // @j-weave: This doesn't *need* to be in global state anymore, but we're doing this for backwards compat.
    // Note that this abstraction is what is exposed, and not the global store that powers it - this is intentional
    // as eventually this will become based entirely in hook state.

    if (lens === 'preview') {
      setIsVersionHistoryPanelOpen(true);

      setIsRefinementPanelOpen(false);
      setIsReferencesPanelOpen(false);
      setIsReviewPanelOpen(false);
      setIsAutoUpdatePanelOpen(false);
      return;
    }

    if (lens === 'refinement') {
      setIsRefinementPanelOpen(true);

      setIsReferencesPanelOpen(false);
      setIsReviewPanelOpen(false);
      setIsVersionHistoryPanelOpen(false);
      return;
    }
    if (lens === 'references') {
      setIsReferencesPanelOpen(true);

      setIsRefinementPanelOpen(false);
      setIsReviewPanelOpen(false);
      setIsVersionHistoryPanelOpen(false);
      return;
    }
    if (lens === 'review') {
      setIsReviewPanelOpen(true);

      setIsReferencesPanelOpen(false);
      setIsRefinementPanelOpen(false);
      setIsVersionHistoryPanelOpen(false);
      return;
    }

    if (lens === 'version') {
      setIsVersionHistoryPanelOpen(true);

      setIsReferencesPanelOpen(false);
      setIsRefinementPanelOpen(false);
      setIsReviewPanelOpen(false);
      setIsAutoUpdatePanelOpen(false);
      return;
    }

    // Otherwise, we're in edit mode - close all of them.
    setIsReferencesPanelOpen(false);
    setIsRefinementPanelOpen(false);
    setIsReviewPanelOpen(false);
    setIsVersionHistoryPanelOpen(false);
  };

  const onFocusBlock = (
    focusedSection: DocumentSection,
    focusedBlock: TopLevelDocumentBlock,
    index: number,
  ) => {
    setCurrentFocusedElement({
      sectionNumber: focusedSection.section_number,
      blockId: focusedBlock.id,
      blockIndex: index,
    });
    setSelectedBlocks([
      {
        blockId: focusedBlock.id,
        sectionNumber: focusedSection.section_number,
      },
    ]);
  };

  const [availableTemplates, setAvailableTemplates] = useState<
    TemplateTableBlock[]
  >([]);

  const [relevantExtractedTables, setRelevantExtractedTables] =
    useState<DocumentReferenceFileTableSummary>({
      indId: indId,
      sectionNumber: documentNumber,
      documents: [],
    });

  const asyncGetDocumentLock = async () => {
    if (canEditDocument) {
      const documentLock = await DocumentEditorService.lockDocument({
        indId: indId,
        docId: documentNumber,
      });
      if (!documentLock || documentNumber === '') {
        setIsLockedToUser(false);
        return;
      }
      if (currentUserProfile.email) {
        setIsLockedToUser(documentLock.email !== currentUserProfile.email);
        setLockHolder(documentLock.friendlyName);
      }
    }
  };

  useAsync(async () => {
    if (!templatesLoaded) {
      setTemplatesLoaded(true);

      const templates = await TemplateService.getTemplateList();
      setAvailableTemplates(() => templates);
    }
  }, [templatesLoaded]);

  useAsync(async () => {
    const files = await fileService.getFiles({
      indId: indId,
      includeImagePreviewUrl: false,
    });
    setIndFiles(() => files);
  }, [indId]);

  useAsync(async () => {
    if (documentNumber === '') {
      console.debug('Empty document number');
      return;
    }
    if (!tableSummariesLoaded) {
      setTableSummariesLoaded(true);
      const relatedTableSummary = await TableService.getAssociatedTables({
        indId: indId,
        sectionNumber: documentNumber,
      });
      setRelevantExtractedTables(relatedTableSummary);
      asyncGetDocumentLock();
    }
  }, [documentNumber, tableSummariesLoaded]);

  // fetch the document content
  useAsync(async () => {
    try {
      await fetchDocument();
    } catch (error) {
      logger.logError(`Error in fetching document: ${error}`, {
        error,
      });
    }
  }, [documentNumber]);

  const togglePreviewMode = async (
    versionRecord: VersionControlRecord | null,
  ): Promise<DocumentSection[] | null> => {
    if (versionRecord !== null) {
      shiftLens('preview');
      console.debug('TOGGLE PREVIEW MODE', versionRecord);
      return await fetchDocumentByVersion(versionRecord);
    } else {
      shiftLens('edit'); // No need to refetch here.
      return Promise.resolve(null);
    }
  };

  const loadPreviewVersion = (
    versionRecord: VersionControlRecord | null,
  ): Promise<DocumentSection[] | null> => {
    try {
      setHasPreviewVersionRecord(versionRecord !== null);
      return togglePreviewMode(versionRecord);
    } catch (error) {
      logger.logError(`Error in previewing document version: ${error}`, {
        error,
      });
      return Promise.resolve(null);
    }
  };

  const fetchDocument = async () => {
    if (documentNumber === '') {
      console.debug('Empty document number');
      return;
    }
    const documentContentArray = await DocumentEditorService.getDocument({
      indId,
      documentNumber,
    });

    setDocumentSections(() => documentContentArray);
    if (documentContentArray.length > 0) {
      setDocumentStatus(() => documentContentArray[0].status);
      setDocumentId(() => documentContentArray[0]?.document_id ?? null);
    }

    // If the document content is generating or inqueue, poll for updates until we receive word the document
    // is done generating
    if (
      documentContentArray.length > 0 &&
      isProcessing(documentContentArray[0])
    ) {
      pollForDocumentUpdates();
    }
  };

  const fetchDocumentByVersion = async (
    versionRecord: VersionControlRecord,
  ): Promise<DocumentSection[] | null> => {
    try {
      const documentContentArray =
        await DocumentEditorService.getDocumentByVersion({
          indId,
          documentNumber: versionRecord.number,
          version: versionRecord.version,
        });
      return documentContentArray;
    } catch (error) {
      toast.error({
        message: String(error),
      });
      return Promise.resolve(null);
    }
  };

  const pollForDocumentUpdates = () => {
    const pendingDocumentIntervalId = setInterval(() => {
      DocumentEditorService.getDocument({
        indId: indId,
        documentNumber,
      }).then((updatedDocumentContentArray) => {
        // Update the state only if things have changed in between polls
        if (
          JSON.stringify(updatedDocumentContentArray) !==
          JSON.stringify([...documentSections])
        ) {
          if (updatedDocumentContentArray.length > 0) {
            setDocumentStatus(() => updatedDocumentContentArray[0].status);
            setDocumentId(
              () => updatedDocumentContentArray[0]?.document_id ?? null,
            );
          }
          setDocumentSections(() => updatedDocumentContentArray);
        }

        // Clear the interval once the document has indicated it is done generating
        if (
          updatedDocumentContentArray.length > 0 &&
          !isProcessing(updatedDocumentContentArray[0])
        ) {
          clearInterval(pendingDocumentIntervalId);
        }
      });
    }, intervalToCheckForGeneratingSections);
  };

  const updateDocumentSection = async (
    updatedDocumentSection: DocumentSection,
  ) => {
    console.info('Attempting to update the document section.');
    try {
      setIsSaving(true);
      await DocumentEditorService.saveDocumentSection({
        documentSection: updatedDocumentSection,
        indId: indId,
        sectionNumber: updatedDocumentSection.section_number,
      });
      const docSectionWithUpdatedOffsets =
        await DocumentEditorService.getDocumentSection({
          indId: indId,
          sectionNumber: updatedDocumentSection.section_number,
        });
      setIsPendingReferencesRefreshAfterEdit(true);
      const newDocumentSections = cloneDeep(mostRecentDocumentSections.current);
      const sectionIndexToUpdate = newDocumentSections.findIndex(
        (docSection) =>
          docSection.section_number === updatedDocumentSection.section_number,
      );
      if (sectionIndexToUpdate === -1) {
        throw new Error(
          `Couldn't find matching section to update for ${updatedDocumentSection.section_number}`,
        );
      }
      updatedDocumentSection.children = updatedDocumentSection.children.map(
        (block) => {
          if (block.block_type === 'text') {
            return {
              ...block,
              properties: {
                ...block.properties,
                sentences: docSectionWithUpdatedOffsets.children.find(
                  (child) => child.id === block.id,
                )?.properties.sentences,
              },
            };
          }
          return block;
        },
      );

      newDocumentSections[sectionIndexToUpdate] = updatedDocumentSection;
      setDocumentSections(() => newDocumentSections);
    } catch (e) {
      logger.logError(
        `Error encountered while trying to save section ${updatedDocumentSection.section_number}`,
        { context: e },
      );
      toast.error(
        `We weren't able to save your changes to section ${updatedDocumentSection.section_number}.`,
      );
    } finally {
      // Ensure a minimal delay between saving and setting the state back.
      setTimeout(() => setIsSaving(false), 1500);
    }
  };

  const initializeDocumentSection = async (
    updatedDocumentSection: DocumentSection,
  ) => {
    try {
      await DocumentEditorService.saveDocumentSection({
        documentSection: updatedDocumentSection,
        indId: indId,
        sectionNumber: updatedDocumentSection.section_number,
      });
      await refreshDocumentSection(updatedDocumentSection.section_number);
    } catch (e) {
      logger.logError(
        `Error encountered while trying to initialize section ${updatedDocumentSection.section_number}`,
        { context: e },
      );
    }
  };

  const refreshDocumentSection = async (sectionNumber: string) => {
    const sectionContentTree = (await DocumentEditorService.getDocumentSection({
      indId: indId,
      sectionNumber,
    })) as DocumentSection;
    if (!sectionContentTree) {
      logger.logError(`No content tree returned for section refresh`, {
        indId: indId,
        sectionNumber: sectionNumber,
      });
      return;
    }
    const newDocumentSections = cloneDeep(documentSections);
    const sectionIndexToUpdate = newDocumentSections.findIndex(
      (docSection) => docSection.section_number === sectionNumber,
    );
    if (sectionIndexToUpdate === -1) {
      throw new Error(
        `Couldn't find matching section to update for ${sectionNumber}`,
      );
    }
    newDocumentSections[sectionIndexToUpdate] = sectionContentTree;
    setDocumentSections(() => newDocumentSections);
    if (isProcessing(sectionContentTree)) {
      pollForSectionUpdates(sectionNumber);
    }
  };

  const pollForSectionUpdates = (sectionNumber: string) => {
    const pendingSectionIntervalId = setInterval(() => {
      DocumentEditorService.getDocumentSection({
        indId: indId,
        sectionNumber,
      }).then((updatedSection) => {
        const sectionIndexToUpdate = documentSections.findIndex(
          (docSection) => docSection.section_number === sectionNumber,
        );
        // Update the state only if the status has changed in between polls
        if (
          JSON.stringify(documentSections[sectionIndexToUpdate]) !==
          JSON.stringify(updatedSection)
        ) {
          const newDocumentSections = cloneDeep(documentSections);
          if (sectionIndexToUpdate === -1) {
            throw new Error(
              `Couldn't find matching section to update for ${sectionNumber}`,
            );
          }
          newDocumentSections[sectionIndexToUpdate] = updatedSection;
          setDocumentSections(() => newDocumentSections);
        }

        // Clear the interval once the section is no longer generating
        if (!isProcessing(updatedSection)) {
          clearInterval(pendingSectionIntervalId);
        }
      });
    }, intervalToCheckForGeneratingSections);
  };

  const changeDocumentTo = async (documentNumber: string) => {
    setDocumentNumber(() => documentNumber);
  };

  const insertTemplatedTable: ({
    templateId,
    sectionNumber,
    blockPositionToInsert,
    fileIds,
  }: InsertTemplatedTableRequest) => void = ({
    templateId,
    sectionNumber,
    blockPositionToInsert,
    fileIds,
  }) => {
    const insertTemplatedTableAsync = async () => {
      const result = await TemplateService.processTemplate({
        indId,
        documentNumber,
        sectionNumber,
        positionToInsertTemplate: blockPositionToInsert,
        templateId,
        fileIds,
      });
      if (result) {
        toast.success('Processing your template!  Please wait a few seconds.');
      } else {
        toast.error(
          "Something unexpected happened when processing your template.  We're on it.",
        );
      }
      refreshDocumentSection(sectionNumber);
    };
    insertTemplatedTableAsync();
  };

  const [currentStatus, setCurrentStatus] = useState<
    DocumentSectionStatus | undefined
  >();

  const [processingStatus, setProcessingStatus] =
    useState<ProcessingStatus>(null);

  useDeepCompareEffect(() => {
    if (documentSections.length > 0) {
      // We treat the document's status as the status of the first section returned (which represents the entire document)
      setCurrentStatus(documentSections[0].status);
      setProcessingStatus(documentSections[0].processing_status);
    }
  }, [documentSections]);

  const insertImage: ({
    imageFile,
    sectionNumber,
    blockPositionToInsert,
  }: {
    imageFile: any;
    sectionNumber: string;
    blockPositionToInsert: number;
  }) => void = ({ imageFile, sectionNumber, blockPositionToInsert }) => {
    const insertImageAsync = async () => {
      const result = await DocumentEditorService.processImage({
        imageFile,
        indId,
        documentNumber,
        sectionNumber,
        positionToInsertImage: blockPositionToInsert,
      });
      if (result) {
        toast.success(
          'Updating document with your image!  Please wait a few seconds.',
        );
      } else {
        toast.error(
          "Something unexpected happened when uploading the image.  We're on it.",
        );
      }
      refreshDocumentSection(sectionNumber);
    };
    insertImageAsync();
  };

  return {
    showTemplatedTableInstructions,
    availableTemplates,
    changeDocumentTo,
    currentFocusedElement,
    currentLens,
    currentStatus,
    documentId,
    documentSections,
    initializeDocumentSection,
    frontMatterSections,
    insertTemplatedTable,
    insertImage,
    onFocusBlock,
    indFiles,
    isLoading,
    isLockedToUser,
    isSaving,
    lockHolder,
    refreshDocumentSection,
    relevantExtractedTables,
    shiftLens,
    status: documentStatus,
    processingStatus,
    updateDocumentSection,
    loadPreviewVersion,
    fetchDocumentByVersion,
    fetchDocument,
    isReloadingDocumentContent,
    setIsReloadingDocumentContent,
    versionDocumentSectionList,
    setVersionDocumentSectionList,
    resetPreviewVersionRecord,
    versionDocumentFigures,
    versionDocumentTables,
  };
};
