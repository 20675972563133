import { DataFile } from '~/core/domain/types';

import { TemplateFormState } from '../hooks/useTemplateFormContext';
import {
  ChainType,
  GenerateContentByPromptConfigPayload,
  PromptConfig,
  TemplateModule,
  TemplateOption,
  TemplateSection,
} from '../types';

export const DISABLED_MODULES = ['1', '4', '5'];
export const initializeTemplateModuleStatus = ({
  modules,
  allowModule1 = false,
}: {
  modules: TemplateModule[];
  allowModule1?: boolean;
}) => {
  // create a dynamic list of disabled modules
  const effectiveDisabledModules =
    allowModule1 ?
      DISABLED_MODULES.filter((module) => module !== '1')
    : DISABLED_MODULES;

  modules.forEach((module) => {
    module.disabled = !!effectiveDisabledModules.includes(module.module_number);
  });
};

export const createOption = (
  section: TemplateSection,
  moduleNumber: number,
): TemplateOption => {
  const label = `${section.section_number} ${section.section_title}`;
  return {
    value: section,
    label,
    key: section.id,
    moduleNumber,
  };
};

export type GetDocumentOptionListArgs = {
  moduleNumber: number;
  sections: TemplateSection[];
  module3inclusion?: 'allowModule3' | 'disallowModule3';
};

export const getDocumentOptionList = ({
  moduleNumber,
  sections,
  module3inclusion = 'disallowModule3',
}: GetDocumentOptionListArgs): TemplateOption[] => {
  const newOptions: TemplateOption[] = [];

  const isConfigValid = (config: any) =>
    config && Object.keys(config).length > 0;

  const sectionShouldBeIncluded = (section: TemplateSection): boolean => {
    if (
      section.section_number === '2.3' &&
      module3inclusion === 'disallowModule3'
    ) {
      return false;
    }

    if (moduleNumber === 2) {
      if (section.is_terminal && section.is_document && section.is_supported) {
        return isConfigValid(section.default_config);
      }

      if (
        section.is_supported &&
        section.is_document &&
        section.subsections.length > 0 &&
        !section.is_terminal
      ) {
        return true;
      }
    }

    if (moduleNumber === 3) {
      return true;
    }

    return false;
  };

  const addDocumentOptions = (sections: TemplateSection[]) => {
    sections.forEach((section) => {
      if (sectionShouldBeIncluded(section)) {
        newOptions.push(createOption(section, moduleNumber));
      }

      if (section.subsections.length > 0) {
        addDocumentOptions(section.subsections);
      }
    });
  };

  addDocumentOptions(sections);

  return newOptions;
};

export const createConfigByInstruction = (
  instruction: string,
  template: string | undefined,
) => {
  if (!template) return undefined;

  return {
    all: {
      human_prompt_template: {
        [template]: instruction,
      },
      system_prompt_template: {
        [template]: instruction,
      },
    },
  };
};

export const createConfig = (
  promptConfig: PromptConfig,
  template: string | undefined,
) => {
  if (!template) return undefined;

  const { human_prompt_template = {} } = promptConfig.all || {};
  const templateKey = template as keyof typeof human_prompt_template;
  const newInstruction = human_prompt_template[templateKey] || '';

  if (newInstruction.trim().length === 0) {
    return undefined;
  }

  return {
    all: {
      human_prompt_template: {
        [template]: newInstruction,
      },
      system_prompt_template: {
        [template]: newInstruction,
      },
    },
  };
};

export const createPayloadByTemplateFormState = (
  templateFormState: TemplateFormState,
  promptConfig: PromptConfig,
  selectedFiles: DataFile[],
): GenerateContentByPromptConfigPayload => {
  const files = selectedFiles.map((file) => ({
    file_id: file.id,
    doctype: file.documentTypeName,
  }));

  const indLevelInstruction =
    templateFormState.indLevelPromptConfig?.all?.human_prompt_template
      .ind_level_instruction || '';
  const docLevelInstruction =
    templateFormState.docLevelPromptConfig?.all?.human_prompt_template
      .higher_level_instruction || '';

  const docPromptConfig = createConfigByInstruction(
    docLevelInstruction,
    'higher_level_instruction',
  );
  const indPromptConfig = createConfigByInstruction(
    indLevelInstruction,
    'ind_level_instruction',
  );

  return {
    files,
    prompt_config: promptConfig,
    doc_prompt_config: docPromptConfig,
    ind_prompt_config: indPromptConfig,
  };
};

export const getPayload = (
  promptConfig: PromptConfig,
  selectedFiles: DataFile[],
): GenerateContentByPromptConfigPayload => {
  const files = selectedFiles.map((file) => ({
    file_id: file.id,
    doctype: file.documentTypeName,
  }));

  const docPromptConfig = createConfig(
    promptConfig,
    'higher_level_instruction',
  );
  const indPromptConfig = createConfig(promptConfig, 'ind_level_instruction');

  return {
    files,
    prompt_config: promptConfig,
    doc_prompt_config: docPromptConfig,
    ind_prompt_config: indPromptConfig,
  };
};

export const sanitizeGenerateContentPayload = (
  payload: GenerateContentByPromptConfigPayload,
) => {
  const sanitizedPayload = { ...payload };
  if (
    sanitizedPayload.ind_prompt_config?.all?.human_prompt_template.ind_level_instruction?.trim()
      .length === 0
  ) {
    delete sanitizedPayload.ind_prompt_config;
  }
  if (
    sanitizedPayload.doc_prompt_config?.all?.human_prompt_template.higher_level_instruction?.trim()
      .length === 0
  ) {
    delete sanitizedPayload.doc_prompt_config;
  }
  if (sanitizedPayload.prompt_config.all) {
    delete sanitizedPayload.prompt_config.all;
  }
  return sanitizedPayload;
};

// Create a mapping of chain names to their respective orders
export const mapChainOrdersToChainNames = (currentRecipe: {
  chains: { name: string; order: number }[];
}): Record<string, number> => {
  return currentRecipe.chains.reduce((acc: Record<string, number>, chain) => {
    acc[chain.name] = chain.order;
    return acc;
  }, {});
};

// Sort chainKeys based on the order in chainOrderMap
export const sortChainKeysByOrderMap = (
  chainKeys: ChainType[],
  chainOrderMap: Record<string, number>,
): ChainType[] => {
  return chainKeys.sort((a, b) => {
    // Chains without a specified order will be placed at the end
    const orderA = chainOrderMap[a] !== undefined ? chainOrderMap[a] : Infinity;
    const orderB = chainOrderMap[b] !== undefined ? chainOrderMap[b] : Infinity;
    return orderA - orderB;
  });
};
