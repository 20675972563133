import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import UserContext from '~/core/contexts/UserContext';
import { QueryKeys } from '~/core/hooks/const';
import { useToast } from '~/core/hooks/core/useToast';
import { useCreateIndDocument } from '~/core/hooks/mutation/useCreateIndDocument';
import { useUpdateIndDocument } from '~/core/hooks/mutation/useUpdateIndDocument';
import logger from '~/core/providers/logger';
import { OrganizationMember } from '~/features/organization-management/domain/types';

import * as IndService from '../../../core/services/IndService';
import * as OrganizationService from '../../organization-management/services/OrganizationService';

const sortIndDateDescending = (
  indOne: IndApplication,
  indTwo: IndApplication,
) => {
  const indOneDate = indOne.updatedAt || indOne.createdAt;
  const indTwoDate = indTwo.updatedAt || indTwo.createdAt;

  if (indOneDate === indTwoDate) {
    return 0;
  }

  if (!indOneDate) {
    return 1;
  }

  if (!indTwoDate) {
    return -1;
  }

  return indOneDate < indTwoDate ? 1 : -1;
};

export const useDashboardData = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const refreshDashboard = async () => {
    await queryClient.invalidateQueries({
      queryKey: [QueryKeys.GET_IND_APPLICATIONS()],
    });
    await queryClient.invalidateQueries({ queryKey: QueryKeys.GET_ORG_STATS });
  };

  const { userProfile: currentUserProfile } = useContext(UserContext);

  const [userInds, setUserInds] = useState<Array<IndApplication>>([]);
  const [organizationInds, setOrganizationInds] = useState<
    Array<IndApplication>
  >([]);
  const [indCount, setIndCount] = useState<number>(0);
  const [defaultIndOwner, setDefaultIndOwner] = useState<
    OrganizationMember | undefined
  >();

  const removeInd = useMutation({
    mutationFn: async (id: string) => IndService.deleteInd(id),
    onMutate: async (id: string) => {
      const previousIndSet = queryClient.getQueryData(
        QueryKeys.GET_IND_APPLICATIONS(),
      );
      queryClient.setQueryData(
        QueryKeys.GET_IND_APPLICATIONS(),
        (oldIndSet: Array<IndApplication>) => {
          return oldIndSet.filter((oldInd) => oldInd.id !== id);
        },
      );
      return { previousIndSet };
    },
    onSuccess: () => {
      toast.success('Deleted the IND.');
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(
        QueryKeys.GET_IND_APPLICATIONS(),
        context?.previousIndSet,
      );
      logger.logError(err, {
        queryAction: 'remove-ind',
        indId: id,
      });
      toast.error(
        `Something went wrong while deleting the IND; we're looking into it!`,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_IND_APPLICATIONS(),
      });
    },
  });

  // @j-weave: Legacy code has each mutation as their own hook - not sure about that approach
  // (harder to see what queries are invalidated without the engineer digging in)
  // but have left it this way for the time being for simplicity's sake.

  // Note: definitely an area that could/should be refactored, as some of the hooks below perform
  // data manipulation (mainly around dates) that ideally should be done at a FE service level

  const { mutate: createInd, isPending: creatingInd } = useCreateIndDocument();
  const { mutate: updateInd, isPending: updatingInd } = useUpdateIndDocument();

  const {
    isPending: loadingInds,
    data: inds,
    refetch: refetchInds,
  } = useQuery({
    queryKey: QueryKeys.GET_IND_APPLICATIONS(),
    queryFn: () => IndService.getAllInds(),
  });

  const { isPending: loadingOrgStats, data: orgStats } = useQuery({
    queryKey: QueryKeys.GET_ORG_STATS,
    queryFn: () => OrganizationService.getOrgStats(),
  });

  const { isPending: loadingMembers, data: orgMembers } = useQuery({
    queryKey: QueryKeys.GET_ORG_MEMBERS,
    queryFn: () => OrganizationService.getMembers(),
  });

  useDeepCompareEffect(() => {
    if (inds) {
      setUserInds(
        inds
          .filter((ind) => ind.ownerId === currentUserProfile.uuid)
          .sort(sortIndDateDescending),
      );
      setOrganizationInds(
        inds
          .filter((ind) => ind.ownerId !== currentUserProfile.uuid)
          .sort(sortIndDateDescending),
      );
      setIndCount(inds.length);
    }
  }, [inds || [], currentUserProfile.uuid]);

  useDeepCompareEffect(() => {
    if (orgMembers) {
      const orgMemberForCurrentUser = orgMembers.find(
        (member) => member.uuid === currentUserProfile.uuid,
      );
      setDefaultIndOwner(orgMemberForCurrentUser);
    }
  }, [orgMembers || [], currentUserProfile.uuid]);

  return {
    refreshDashboard,
    currentUserRole: currentUserProfile.role,
    loadingOrgStats,
    orgStats,
    loadingMembers,
    orgMembers,
    defaultIndOwner,
    loadingInds,
    inds,
    indCount,
    userInds,
    organizationInds,
    removeInd: removeInd.mutate,
    createInd,
    creatingInd,
    updateInd,
    refetchInds,
    updatingInd,
  };
};
