import { Tooltip } from 'antd';
import { useContext, useEffect } from 'react';

import { refreshSession } from '~/core/api/session-manager.ts';
import UserContext from '~/core/contexts/UserContext';
import { cn } from '~/core/lib/tailwind-util';
import { Organizations } from '~/core/services/UserService/Response/types';
import { useSessionStore } from '~/core/store/sessions-store';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { organizationsAreDifferent } from '~/features/organization-management/logic';
import { useProfileData } from '~/features/profile/hooks/useProfileData';
export interface OrganizationMenuNotificationProps {
  isOrganizationMenuOpen: boolean;
  organizations: Organizations[];
}

export const OrganizationMenuNotification = ({
  isOrganizationMenuOpen,
  organizations,
}: OrganizationMenuNotificationProps) => {
  const organizationsFromSessionStore =
    useSessionStore().session?.user?.organizations ?? [];
  const { userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);

  const { updateUserSettings } = useProfileData();

  const organizationsHaveDelta = organizationsAreDifferent(
    organizations,
    organizationsFromSessionStore,
  );

  useEffect(() => {
    if (organizationsHaveDelta) {
      updateUserSettings.mutate({
        ...userProfileSettings,
        hasSeenNewChildOrganization: false,
      });
      setUserProfileSettings({
        ...userProfileSettings,
        hasSeenNewChildOrganization: false,
      });
    }

    if (!organizationsHaveDelta || isOrganizationMenuOpen) {
      updateUserSettings.mutate({
        ...userProfileSettings,
        hasSeenNewChildOrganization: true,
        hasSeenContentGenerationGuide: true,
      });
      setUserProfileSettings({
        ...userProfileSettings,
        hasSeenNewChildOrganization: true,
        hasSeenContentGenerationGuide: true,
      });
      refreshSession();
    }
  }, [organizationsHaveDelta, isOrganizationMenuOpen]);

  return (
    <Tooltip
      className={cn(
        'right-32',
        (
          !isOrganizationMenuOpen &&
            !userProfileSettings?.hasSeenNewChildOrganization
        ) ?
          'open'
        : 'not open',
      )}
      placement="bottomLeft"
      title={
        <>
          <BodyText className="font-semibold" size="body3">
            You've been added to a new organization!
          </BodyText>
          <BodyText size="body3">
            Use this menu to switch between your organizations.
          </BodyText>
        </>
      }
      defaultOpen={organizationsHaveDelta}
      open={
        !isOrganizationMenuOpen &&
        !userProfileSettings?.hasSeenNewChildOrganization
      }
    />
  );
};

export default OrganizationMenuNotification;
