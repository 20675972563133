import { CheckCircleFilled } from '@ant-design/icons';
import { Avatar, Divider, Flex } from 'antd';

import { cn } from '~/core/lib/tailwind-util';
import {
  OrganizationFromProfile,
  Organizations,
} from '~/core/services/UserService/Response/types';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { Weave2024Button } from '~/design-system/components/atoms/Weave2024Button';

export interface OrganizationMenuHomeItemProps {
  currentOrganization: OrganizationFromProfile | null;
  findSelectedOrganization: Organizations | null;
  handleSwitchOrganization: (organizationId: string) => void;
  homeOrganizationId: string;
  homeOrganizationLogo: string | null;
  homeOrganizationName: string;
  setIsOrganizationMenuOpen: (isOpen: boolean) => void;
}

export const OrganizationMenuHomeItem = ({
  currentOrganization,
  findSelectedOrganization,
  handleSwitchOrganization,
  homeOrganizationId,
  homeOrganizationLogo,
  homeOrganizationName,
  setIsOrganizationMenuOpen,
}: OrganizationMenuHomeItemProps) => {
  const currentOrganizationIsSelected =
    currentOrganization?.name === homeOrganizationName &&
    findSelectedOrganization?.is_selected;
  return (
    <>
      <Divider className="mt-4 mb-2 border-nuetral-9" data-testid="divider" />
      <BodyText
        className="mb-2 font-semibold text-weave-secondary tracking-wider"
        data-testid="homeOrganizationHeader"
        size="mini"
      >
        HOME ORGANIZATION
      </BodyText>
      <Flex>
        <Weave2024Button
          bypassCapitalization
          className={cn(
            currentOrganizationIsSelected && 'bg-slate-50',
            `w-full justify-between border-0 shadow-none py-6 px-4 text-weave-primary font-semibold hover:bg-slate-50 [&_.notSelectedCheckmark]:hover:visible`,
          )}
          data-testid="homeOrganizationItemButton"
          ghost
          variant="primary"
          onClick={() => {
            if (currentOrganizationIsSelected)
              return setIsOrganizationMenuOpen(false);
            handleSwitchOrganization(homeOrganizationId);
            setIsOrganizationMenuOpen(false);
          }}
        >
          <Flex className="overflow-hidden w-full">
            {homeOrganizationLogo ?
              <Avatar
                className="inline-flex mr-2"
                data-testid="logoAvatar"
                size={25}
                src={homeOrganizationLogo}
              />
            : <Avatar
                className="w-[25px] inline-flex mr-2"
                data-testid="letterAvatar"
                size={25}
              >
                <BodyText className="text-white" size="body3">
                  {homeOrganizationName.charAt(0)}
                </BodyText>
              </Avatar>
            }
            <BodyText
              className="text-ellipsis overflow-hidden font-semibold"
              data-testid="organizationName"
              size="body3"
            >
              {homeOrganizationName}
            </BodyText>
          </Flex>
          {currentOrganizationIsSelected ?
            <CheckCircleFilled
              data-testid="isSelectedCheckmark"
              className="text-weave-color-product-base"
            />
          : <CheckCircleFilled
              data-testid="notSelectedCheckmark"
              className="notSelectedCheckmark invisible text-weave-primary/[.15]"
            />
          }
        </Weave2024Button>
      </Flex>
    </>
  );
};

export default OrganizationMenuHomeItem;
