import { Key } from 'react';

import { Organizations } from '~/core/services/UserService/Response/types';

import { DossierItems } from './domain/types';

export const defaultSelectedKeys = (arr: DossierItems[]) => {
  if (!arr) return [];
  const keys: Key[] = [];

  const traverse = (item: DossierItems) => {
    if (item.selected && item.key) {
      keys.push(item.key);
    }

    if (item.children.length > 0) {
      item.children.forEach((child) => traverse(child));
    }
  };

  arr.forEach((item) => traverse(item));

  return keys;
};

export const organizationsAreDifferent = (
  organizationFromResponse: Organizations[],
  organizationsFromStorage: Organizations[],
) => {
  return (
    organizationFromResponse
      .map((org) => org.organization.uuid)
      .sort()
      .join(',') !==
    organizationsFromStorage
      .map((org) => org.organization.uuid)
      .sort()
      .join(',')
  );
};
