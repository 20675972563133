import { Flex } from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';

import { IndProfile } from '~/core/domain/types';
import { Organizations } from '~/core/services/UserService/Response/types';

import OrganizationMenuActions from './OrganizationMenuActions';
import OrganizationMenuButton from './OrganizationMenuButton';
import OrganizationMenuClientItem from './OrganizationMenuClientItem';
import OrganizationMenuHomeItem from './OrganizationMenuHomeItem';
import OrganizationMenuNotification from './OrganizationMenuNotification';

export interface OrganizationMenuProps {
  allowTemplateManagement: boolean;
  handleSwitchOrganization: (organizationId: string) => void;
  organizations: Organizations[];
  userProfile: IndProfile;
}

export const OrganizationMenu = ({
  allowTemplateManagement,
  handleSwitchOrganization,
  organizations,
  userProfile,
}: OrganizationMenuProps) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, () => {
    setIsOrganizationMenuOpen(false);
  });

  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] =
    useState<boolean>(false);

  const homeOrganizationName = userProfile.organization.name;
  const homeOrganizationId = userProfile.organization.uuid;
  const homeOrganizationLogo = userProfile.organization.logo ?? null;

  const findSelectedOrganization =
    organizations.find((org) => org.is_selected) ?? null;

  const currentOrganization = findSelectedOrganization?.organization ?? null;

  return (
    <Flex className="mr-4" ref={menuRef}>
      <OrganizationMenuButton
        currentOrganization={currentOrganization}
        isOrganizationMenuOpen={isOrganizationMenuOpen}
        setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
      />

      <OrganizationMenuNotification
        organizations={organizations}
        isOrganizationMenuOpen={isOrganizationMenuOpen}
      />

      {isOrganizationMenuOpen && (
        <Flex className="max-w-[300px] flex-col p-4 rounded shadow-lg fixed z-50 right-4 mt-12 border border-solid border-[#1B202B]/[.15] bg-white">
          <OrganizationMenuActions
            allowTemplateManagement={allowTemplateManagement}
            navigate={navigate}
            userProfile={userProfile}
            setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
          />
          <Flex className="flex-col">
            {organizations.length > 1 && (
              <>
                <OrganizationMenuHomeItem
                  currentOrganization={currentOrganization}
                  findSelectedOrganization={findSelectedOrganization}
                  handleSwitchOrganization={handleSwitchOrganization}
                  homeOrganizationId={homeOrganizationId}
                  homeOrganizationLogo={homeOrganizationLogo}
                  homeOrganizationName={homeOrganizationName}
                  setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
                />
                <OrganizationMenuClientItem
                  handleSwitchOrganization={handleSwitchOrganization}
                  homeOrganizationName={homeOrganizationName}
                  organizations={organizations}
                  setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
                />
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default OrganizationMenu;
