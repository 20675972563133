import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { GapAnalysisFilterState } from '~/features/gap-analysis/types';

import { initialFiltersState } from '../contexts/IndManagerContext';
import { IndManagerFilterState } from '../contexts/IndManagerContext/IndManagerProvider';

export type SessionDetailModel = {
  user: User;
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  authTime?: string;
  viewedDocuments?: ViewedDocument[];
  indManagerState?: IndManagerFilterState;
  gapAnalysisState?: GapAnalysisFilterState;
};

interface SessionStore {
  session: SessionDetailModel | null;
  setSession: (session: SessionDetailModel | null) => void;
}

export const useSessionStore = create<SessionStore>()(
  devtools(
    persist(
      (set, get) => ({
        session: {
          user: {
            id: '',
            name: '',
            email: '',
            tags: [],
            homeOrganization: '',
            organizations: [],
          },
          authTime: '',
          idToken: '',
          accessToken: '',
          refreshToken: '',
          viewedDocuments: [],
          indManagerState: {
            filters: initialFiltersState,
            expandedKeys: [],
          },
          gapAnalysisState: {
            filters: initialFiltersState,
            updatedKeys: undefined,
            expandedKeys: [],
          },
        },
        setSession: (session) => {
          if (get().session === session) {
            return;
          }
          set(() => ({ session }));
        },
      }),
      {
        name: 'session-store',
      },
    ),
  ),
);

export const useGetSession = () => useSessionStore((state) => state.session);

export const useSessionStoreActions = () =>
  useSessionStore((state) => ({
    setSession: state.setSession,
  }));
