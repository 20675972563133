import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import logger from '../logger';

interface Props {
  children: React.ReactNode;
}

export const ReactQueryClient = (props: Props) => {
  const enableTanStackDebugger =
    !!import.meta.env.DEV && !import.meta.env.STORYBOOK_ACTIVE;

  const reportQueryClientError = (error: Error) => {
    const message = (error as unknown as ApplicationError).message;
    logger.logError(message, { error });
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        reportQueryClientError(error);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, _, __, _mutation) => {
        reportQueryClientError(error);
      },
    }),
    defaultOptions: {
      mutations: {
        retry: false,
      },
      queries: {
        // 5 minutes
        staleTime: 1000 * 60 * 5,
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {enableTanStackDebugger ?
        <ReactQueryDevtools initialIsOpen={false} />
      : null}
    </QueryClientProvider>
  );
};
