import { ExportOutlined, SettingOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import { useAuth } from '~/core/contexts/AuthContext';
import { IndProfile } from '~/core/domain/types';
import { useToast } from '~/core/hooks/core/useToast';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { Weave2024Button } from '~/design-system/components/atoms/Weave2024Button';
import { PrivateRoutes } from '~/router/private-routes';
import { PublicRoutes } from '~/router/public-routes';

export interface OrganizationMenuActionsProps {
  allowTemplateManagement: boolean;
  navigate: (url: string) => void;
  userProfile: IndProfile;
  setIsOrganizationMenuOpen: (isOpen: boolean) => void;
}

export const OrganizationMenuActions = ({
  allowTemplateManagement,
  navigate,
  userProfile,
  setIsOrganizationMenuOpen,
}: OrganizationMenuActionsProps) => {
  const toast = useToast();
  const { logout } = useAuth();

  return (
    <>
      {userProfile.firstName && userProfile.lastName && (
        <BodyText className="font-semibold" size="body3" data-testid="userName">
          {userProfile.firstName} {userProfile.lastName}
        </BodyText>
      )}
      <BodyText className="mb-4" size="body3" data-testid="userEmail">
        {userProfile.email}
      </BodyText>
      <Flex className="flex-row">
        <Weave2024Button
          className="mr-2 basis-1/2"
          variant="primary"
          icon={<SettingOutlined />}
          size="sm"
          onClick={() => {
            navigate(PrivateRoutes.PROFILE);
            setIsOrganizationMenuOpen(false);
          }}
        >
          Settings
        </Weave2024Button>
        <Weave2024Button
          className="basis-1/2"
          variant="primary"
          icon={<ExportOutlined />}
          size="sm"
          onClick={async () => {
            await logout();
            navigate(PublicRoutes.SIGN_IN);
            setIsOrganizationMenuOpen(false);
            toast.success({
              message: 'You have been signed out successfully',
            });
          }}
        >
          Log out
        </Weave2024Button>
      </Flex>
      {allowTemplateManagement && (
        <BodyText
          className="cursor-pointer text-weave-primary font-semibold mt-4"
          data-testid="templateManagement"
          onClick={() => navigate(PrivateRoutes.MANAGE_TEMPLATES)}
          size="body3"
        >
          Template Management
        </BodyText>
      )}
    </>
  );
};

export default OrganizationMenuActions;
