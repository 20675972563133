import { DocumentSection } from '~/features/document-editor/domain/types';
export const addLeftPaddingBasedOnLevel = (level: number) => {
  const defaultPadding = 16;
  const dynamicPadding = defaultPadding * level;
  return dynamicPadding;
};

export const filterForBlockType = (
  sections: DocumentSection[],
  blockTypes: string[],
) => {
  return sections
    .filter((section) =>
      section.children.some(
        (child) =>
          child.block_type === blockTypes[0] ||
          child.block_type === blockTypes[1],
      ),
    )
    .flatMap((section) => {
      const section_number = section.section_number;
      return section.children
        .filter(
          (child) =>
            child.block_type === blockTypes[0] ||
            child.block_type === blockTypes[1],
        )
        .map((section) => ({
          ...section,
          section_number,
        }));
    });
};
