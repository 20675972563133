import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { WeaveResponseHandlers } from '~/core/weave-response-handlers/WeaveResponseHandlers';

import { TemplateTableBlock } from '../../domain/types';

const indApiAdapter = new IndHttpAdapter();

const templateEndpoints = {
  deleteTemplate: (templateId: string) => `table-templates-admin/${templateId}`,
  getTemplateListing: `table-templates`,
  getTemplateDetail: (templateId: string) =>
    `table-templates-details/${templateId}`,
  processTemplate: (
    indId: string,
    documentNumber: string,
    templateId: string,
  ) =>
    `ind-applications/${indId}/document/${documentNumber}/table-templates-fill/${templateId}`,
  uploadXlsBasedTemplate: `excel-to-template`,
};

const deleteTemplate: ({
  templateId,
}: {
  templateId: string;
}) => Promise<boolean> = async ({ templateId }: { templateId: string }) =>
  WeaveResponseHandlers.passFail(
    indApiAdapter.delete<any>({
      endpoint: templateEndpoints.deleteTemplate(templateId),
      data: null,
    }),
  );

const getTemplateList: (
  type?: 'global' | 'organization' | 'all',
) => Promise<Array<TemplateTableBlock>> = async (type = 'all') =>
  WeaveResponseHandlers.mappedData(
    indApiAdapter.get<Array<TemplateTableBlock>>({
      endpoint: templateEndpoints.getTemplateListing,
    }),
    (data) => {
      if (type === 'global') {
        return data.filter(
          (template) => template.properties.template_type === 'global',
        );
      } else if (type === 'organization') {
        return data.filter(
          (template) =>
            !template.properties.template_type ||
            template.properties.template_type === 'organization',
        );
      }
      return data as Array<TemplateTableBlock>;
    },
    [],
  );

const getTemplateDetail: ({
  templateId,
}: {
  templateId: string;
}) => Promise<any> = async ({ templateId }) =>
  WeaveResponseHandlers.data(
    indApiAdapter.get<any>({
      endpoint: templateEndpoints.getTemplateDetail(templateId),
    }),
  );

const processTemplate = async ({
  indId,
  documentNumber,
  sectionNumber,
  positionToInsertTemplate,
  templateId,
  fileIds = [],
}: {
  indId: string;
  documentNumber: string;
  sectionNumber: string;
  positionToInsertTemplate: number;
  templateId: string;
  fileIds?: Array<string>;
}) =>
  WeaveResponseHandlers.passFail(
    indApiAdapter.post<any>({
      endpoint: templateEndpoints.processTemplate(
        indId,
        documentNumber,
        templateId,
      ),
      data: {
        section_number: sectionNumber,
        position: positionToInsertTemplate,
        files: fileIds,
      },
    }),
  );

const uploadXlsBasedTemplate: ({
  templateFile,
}: {
  templateFile: any;
}) => Promise<boolean> = async ({ templateFile }: { templateFile: any }) =>
  WeaveResponseHandlers.passFail(
    indApiAdapter.post<any>({
      endpoint: templateEndpoints.uploadXlsBasedTemplate,
      data: {
        file: templateFile,
        friendlyName: templateFile.friendlyName ?? '',
        category: templateFile.category ?? '',
      },
      headerOverride: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment; filename="${templateFile.name}"`,
      },
    }),
  );

export {
  deleteTemplate,
  getTemplateDetail,
  getTemplateList,
  processTemplate,
  uploadXlsBasedTemplate,
};
