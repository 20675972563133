import { FriendlyNameMapper } from '~/core/mappers/friendlyNameMapper';
import { OrganizationMember } from '~/features/organization-management/domain/types';

const mapGetOrganizationMemberResponseToOrganizationMember = (
  apiMember: any,
): OrganizationMember => {
  return {
    uuid: apiMember.uuid,
    email: apiMember.email,
    firstName: apiMember.first_name,
    lastName: apiMember.last_name,
    friendlyName:
      FriendlyNameMapper.map.profileResponseData.to.friendlyName(apiMember),
    invitationId: apiMember.invitation_id,
    role: apiMember.role,
    status: apiMember.invitation_status,
    isActive: apiMember.is_active,
    assignedInds: 0, // Note: this is not currently returned in the payload
  };
};

export const OrganizationMemberMapper = {
  map: {
    getOrganizationMemberResponse: {
      to: {
        organizationMember:
          mapGetOrganizationMemberResponseToOrganizationMember,
      },
    },
  },
};
