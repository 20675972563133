import { useState } from 'react';
import { useAsync } from 'react-use';

import { IndProfile, IndUserProfileSettings } from '~/core/domain/types';
import { useSessionValidation } from '~/core/hooks/core/useSessionValidation';
import * as UserService from '~/core/services/UserService';

import UserContext from '.';

interface UserProviderProps {
  children: React.ReactNode;
  onSessionInvalid: () => void;
}

export const UserProvider = ({
  children,
  onSessionInvalid,
}: UserProviderProps) => {
  const [userProfile, setUserProfile] = useState<IndProfile>(
    UserService.defaultProfile,
  );
  const [userProfileSettings, setUserProfileSettings] =
    useState<IndUserProfileSettings | null>(null);

  useSessionValidation(onSessionInvalid);

  useAsync(async () => {
    const profile = await UserService.getProfile();
    setUserProfile(profile);
    UserService.identifyUser(profile);
  }, []);

  useAsync(async () => {
    const settings = await UserService.getProfileSettings();
    setUserProfileSettings(settings);
  }, []);

  return (
    <UserContext.Provider
      value={{
        userProfile,
        userProfileSettings,
        setUserProfileSettings,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
