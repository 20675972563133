import { HttpStatusCode } from 'axios';

import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { IndProfile, IndUserProfileSettings } from '~/core/domain/types';
import logger from '~/core/providers/logger';
import { SessionDetailModel } from '~/core/store/sessions-store.ts';
import { OrganizationRole } from '~/features/organization-management/domain/types';

import { UserServiceMapper } from './Response/mappers';
import {
  ProfileEndpointApiResponse,
  ProfileSettingsEndpointApiResponse,
} from './Response/types';
import { PendoUserReportingProvider } from './UserProviders/PendoUserReportingProvider';
import { PosthogUserReportingProvider } from './UserProviders/PosthogUserReportingProvider';
import { SentryUserReportingProvider } from './UserProviders/SentryUserReportingProvider';

const indApiAdapter = new IndHttpAdapter();

const userServiceEndpoints = {
  profile: `account/profile`,
  settings: `account/profile/settings`,
  ssoConfig: `account/sso/status`,
};

export interface UserReportingProvider {
  identify: (profile: IndProfile) => void;
  resetIdentification: () => void;
}

const userReportingProviders = [
  new PosthogUserReportingProvider(),
  new SentryUserReportingProvider(),
  new PendoUserReportingProvider(),
];

export const defaultProfile: IndProfile = {
  firstName: '',
  lastName: '',
  friendlyName: '',
  role: OrganizationRole.Member,
  uuid: '',
  organizations: [],
  organization: {
    created_at: '',
    uuid: '',
    name: '',
    slug: '',
    type: '',
    updated_at: '',
    logo: null,
  },
  inds: [],
  email: '',
};

export const defaultProfileSettings: IndUserProfileSettings = {
  hasSeenContentGenerationGuide: false,
  hasSeenNewChildOrganization: false,
};

const getProfile: () => Promise<IndProfile> = async () => {
  const profileResponse = await indApiAdapter.get<ProfileEndpointApiResponse>({
    endpoint: userServiceEndpoints.profile,
  });
  if (profileResponse.error) {
    logger.logError(profileResponse.error.message, {
      ...profileResponse.error,
    });
    return defaultProfile;
  }

  const profile = UserServiceMapper.map.profileApiResponse.to.indProfile(
    profileResponse.data as ProfileEndpointApiResponse,
  );

  return profile as IndProfile;
};

export const identifyUser: (profile: IndProfile) => void = (profile) => {
  userReportingProviders.forEach((provider: UserReportingProvider) =>
    provider.identify(profile),
  );
};

export const resetIdentification: () => void = () => {
  userReportingProviders.forEach((provider: UserReportingProvider) =>
    provider.resetIdentification(),
  );
};

export const checkAuthValidity: () => Promise<boolean> = async () => {
  const profileResponse = await indApiAdapter.get<ProfileEndpointApiResponse>({
    endpoint: userServiceEndpoints.profile,
    expectDataPayloadAccompanyingErrorCodes: [HttpStatusCode.Unauthorized],
  });

  if (profileResponse.error) {
    switch (profileResponse.error.status) {
      case 401:
        return false;
      case 555:
      case 556:
        logger.logError(
          `Failure to connect to backend: ${profileResponse.error.status}`,
        );
        return true;
      default:
        logger.logError(
          'Unexpected error in checking auth validity in UserService:',
          {
            ...profileResponse.error,
          },
        );
        return true;
    }
  }

  return true;
};

const getProfileSettings: () => Promise<IndUserProfileSettings> = async () => {
  const settingsResponse =
    await indApiAdapter.get<ProfileSettingsEndpointApiResponse>({
      endpoint: userServiceEndpoints.settings,
    });
  if (settingsResponse.error) {
    logger.logError(settingsResponse.error.message, {
      ...settingsResponse.error,
    });
    return defaultProfileSettings;
  }

  return settingsResponse.data?.settings as IndUserProfileSettings;
};

const updateProfileSettings: (
  data: IndUserProfileSettings,
) => Promise<IndUserProfileSettings> = async (data: IndUserProfileSettings) => {
  const settingsUpdateResponse =
    await indApiAdapter.put<ProfileSettingsEndpointApiResponse>({
      endpoint: userServiceEndpoints.settings,
      data,
    });
  if (settingsUpdateResponse.error) {
    logger.logError(settingsUpdateResponse.error.message, {
      ...settingsUpdateResponse.error,
    });
    return defaultProfileSettings;
  }

  return settingsUpdateResponse.data?.settings as IndUserProfileSettings;
};

const getCurrentUserSessionSession = async () => {
  // This function is used to get the current user session.
  // However, it is designed based on the prior auth implementation.
  // Some or all of this function may be able to be removed or refactored
  try {
    let session: SessionDetailModel | null = null;

    const userProfile = await getProfile();

    const sessionDetails = {
      id: userProfile.uuid,
      name: userProfile.friendlyName,
      email: userProfile.email,
      tags: [],
      homeOrganization: userProfile.organization.name,
      organizations: userProfile.organizations,
    };

    session = {
      user: sessionDetails,
      // Provide default or empty values for legacy JWT-related properties
      // The unneeded properties should be removed from here, and a type
      // for this updated session object should be created.
      authTime: '',
      idToken: '',
      accessToken: '',
      refreshToken: '',
      viewedDocuments: [],
      indManagerState: {},
    };
    return session;
  } catch (error) {
    return null;
  }
};

export const getSsoConfig = async (
  email: string,
): Promise<{ sso_enabled: boolean; sso_url: string | null }> => {
  const response = await indApiAdapter.post<{
    sso_enabled: boolean;
    sso_url: string | null;
  }>({
    endpoint: userServiceEndpoints.ssoConfig,
    data: { email_address: email },
  });

  if (response.error || !response.data) {
    logger.logError(
      response.error ? response.error.message : 'No data received',
      response.error,
    );
    return { sso_enabled: false, sso_url: null };
  }

  const data = response.data;
  return {
    sso_enabled: data.sso_enabled,
    sso_url: data.sso_url || null,
  };
};

export {
  getCurrentUserSessionSession,
  getProfile,
  getProfileSettings,
  updateProfileSettings,
};
