import { Avatar, Flex } from 'antd';

import { OrganizationFromProfile } from '~/core/services/UserService/Response/types';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { Weave2024Button } from '~/design-system/components/atoms/Weave2024Button';

export interface OrganizationMenuButtonProps {
  currentOrganization: OrganizationFromProfile | null;
  isOrganizationMenuOpen: boolean;
  setIsOrganizationMenuOpen: (isOpen: boolean) => void;
}

export const OrganizationMenuButton = ({
  currentOrganization,
  isOrganizationMenuOpen,
  setIsOrganizationMenuOpen,
}: OrganizationMenuButtonProps) => {
  return (
    <Weave2024Button
      bypassCapitalization
      className="max-w-[250px] py-5 focus:border-weave-primary/[.15] hover:border-weave-primary/[.15] enabled:hover:border-weave-primary/[.15] hover:bg-slate-50"
      data-testid="organizationMenuTrigger"
      ghost
      variant="primary"
      onClick={() => setIsOrganizationMenuOpen(!isOrganizationMenuOpen)}
    >
      <Flex className="overflow-hidden">
        {currentOrganization?.logo ?
          <Avatar
            className="inline-flex mr-2"
            data-testid="logoAvatar"
            size={25}
            src={currentOrganization?.logo}
          />
        : <Avatar
            className="width-[25px] inline-flex mr-2"
            data-testid="letterAvatar"
            size={25}
          >
            <BodyText className="text-white" size="body3">
              {currentOrganization?.name?.charAt(0)}
            </BodyText>
          </Avatar>
        }
        <BodyText
          className="max-w-[175px] text-ellipsis overflow-hidden font-semibold"
          data-testid="currentOrganizationName"
          size="body3"
        >
          {currentOrganization?.name}
        </BodyText>
      </Flex>
    </Weave2024Button>
  );
};

export default OrganizationMenuButton;
