import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { OrganizationMemberMapper } from '~/core/mappers/organizationMemberMapper';
import logger from '~/core/providers/logger';
import {
  CreateTemplatePayload,
  GenerateContentProps,
  GenerateContentResponse,
  GenerationResponse,
  HarmonizedGenerationTemplate,
  IndGenerationTemplateResponse,
  OrgTemplates,
} from '~/features/templates-space/types';

import { sanitizeGenerateContentPayload } from '../../utils/templatesUtil';

const indApiAdapter = new IndHttpAdapter();

const templatesEndpoints = {
  deleteTemplate: (templateName: string) =>
    `generation-template/${templateName}`,
  orgContentTemplates: () => `generation-templates`,
  generationTemplatesByInd: (indId: string) =>
    `ind/${indId}/generation-templates`,
  generationTemplatesByName: (name: string) => `generation-template/${name}`,
  cloneTemplate: (sourceTemplateName: string, newTemplateName: string) =>
    `generation-template/${sourceTemplateName}/clone/${newTemplateName}`,
  overridePromptConfig: (templateName: string) =>
    `generation-template/${templateName}`,
  generateContentsFromPromptTemplate: (indId: string, sectionNumber: string) =>
    `ind/${indId}/section/${sectionNumber}/generate-contents-from-prompt-template`,
  promptsGeneratedContent: (
    indId: string,
    sectionNumber: string,
    generationId: string,
  ) =>
    `ind/${indId}/section/${sectionNumber}/prompt-generated-contents/${generationId}`,
};

export const getGenerationTemplatesbyInd = async (
  indId: string,
): Promise<HarmonizedGenerationTemplate> => {
  const endpoint = templatesEndpoints.generationTemplatesByInd(indId);
  const response = await indApiAdapter.get<IndGenerationTemplateResponse>({
    endpoint,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(`Error in returning template by indId: ${response.error}`);
  }

  // The ind content response returns a set of props that are slightly different than the
  // response returned by the organization one, so we map this accordingly.  This has been done straight in the method
  // as at some point the BE will harmonize its response payload to the FE.

  if (!response.data) {
    logger.logError(
      'Generation Templates by ID: No data returned from the endpoint.',
    );
  }

  const originalResponse = response.data as IndGenerationTemplateResponse;

  const harmonizedTemplate: HarmonizedGenerationTemplate = {
    ...originalResponse,
    created_at: originalResponse.template_created_at || '',
    creator_user:
      originalResponse.template_creator_user ?
        OrganizationMemberMapper.map.getOrganizationMemberResponse.to.organizationMember(
          originalResponse.template_creator_user,
        )
      : undefined,
    updated_at: originalResponse.template_updated_at || '',
    updater_user:
      originalResponse.template_updater_user ?
        OrganizationMemberMapper.map.getOrganizationMemberResponse.to.organizationMember(
          originalResponse.template_updater_user,
        )
      : undefined,
    name: originalResponse.template_name || '',
    ind_count: originalResponse.template_ind_count || 0,
    chains: originalResponse.chains || [],
  };

  return harmonizedTemplate;
};

export const getGenerationTemplatesByName = async (
  name: string,
): Promise<HarmonizedGenerationTemplate> => {
  const endpoint = templatesEndpoints.generationTemplatesByName(name);
  const response = await indApiAdapter.get<HarmonizedGenerationTemplate>({
    endpoint,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(`Error in returning template by name: ${response.error}`);
  }

  if (!response.data) {
    logger.logError(
      'Generation Templates by Name: No data returned from the endpoint.',
    );
  }

  const finalizedResponse: HarmonizedGenerationTemplate = {
    ...response.data!,
    creator_user:
      response.data!.creator_user ?
        OrganizationMemberMapper.map.getOrganizationMemberResponse.to.organizationMember(
          response.data!.creator_user,
        )
      : undefined,
    updater_user:
      response.data!.updater_user ?
        OrganizationMemberMapper.map.getOrganizationMemberResponse.to.organizationMember(
          response.data!.updater_user,
        )
      : undefined,
    // @ts-expect-error The payload for the organization response is slightly different than the one for the ind response,
    // which has the count already in it.
    ind_count: response.data.inds ? response.data.inds.length : 0,
  };

  return finalizedResponse;
};

export const savePromptConfig = async ({
  data,
  templateName,
}: CreateTemplatePayload): Promise<void> => {
  const endpoint = templatesEndpoints.overridePromptConfig(templateName);

  const response = await indApiAdapter.put<void>({ endpoint, data });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }
};

export const cloneTemplate = async ({
  sourceTemplateName,
  newTemplateName,
}: {
  sourceTemplateName: string;
  newTemplateName: string;
}) => {
  const endpoint = templatesEndpoints.cloneTemplate(
    sourceTemplateName,
    newTemplateName,
  );
  const response = await indApiAdapter.post<void>({ endpoint, data: {} });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }
};

export const renameTemplate = async ({
  originalTemplateName,
  newTemplateName,
}: {
  originalTemplateName: string;
  newTemplateName: string;
}) => {
  return savePromptConfig({
    templateName: originalTemplateName,
    data: { updated_name: newTemplateName, prompt_config: {} },
  });
};

export const generateContentByPromptConfig = async ({
  indId,
  sectionNumber,
  payload,
}: GenerateContentProps): Promise<GenerateContentResponse> => {
  const endpoint = templatesEndpoints.generateContentsFromPromptTemplate(
    indId,
    sectionNumber,
  );

  const sanitizePayload = sanitizeGenerateContentPayload(payload);

  const response = await indApiAdapter.post<GenerateContentResponse>({
    endpoint,
    data: sanitizePayload,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw new Error(response.error.message);
  }

  return response.data as GenerateContentResponse;
};

export const getGeneratedContentByPrompt = async (
  indId: string,
  sectionNumber: string,
  generationId: string,
): Promise<GenerationResponse> => {
  const endpoint = templatesEndpoints.promptsGeneratedContent(
    indId,
    sectionNumber,
    generationId,
  );
  const response = await indApiAdapter.get<GenerationResponse>({ endpoint });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw new Error(response.error.message);
  }

  return response.data as GenerationResponse;
};

export const getOrgContentTemplates = async (): Promise<OrgTemplates> => {
  const endpoint = templatesEndpoints.orgContentTemplates();
  const response = await indApiAdapter.get<OrgTemplates>({ endpoint });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(`Error in returning org templates: ${response.error}`);
  }

  return response.data as OrgTemplates;
};

export const deleteTemplateByName = async (
  templateName: string,
): Promise<void> => {
  const endpoint = templatesEndpoints.deleteTemplate(templateName);
  const response = await indApiAdapter.delete<void>({ endpoint, data: null });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }
};
